import React from "react";
import Layout from "../Layout/Layout";
import Event from "../components/Event";
import { SectionContainer, StyledEventContainer } from "./Home";

const projects = [
  {
    title: "Rettung des Dorfteiches",
    route: "teich",
    text: "Mitten im Dorf gelegen befindet sich unser Dorfteich. Neben vielen unvergesslichen Sonnentuntergängen, die er uns beschert, ist er auch ein Zuhause für Enten, Fische und Frösche. Leider trocknet unser Teich vom Jahr zu Jahr immer mehr aus. Es ist uns ein Herzensanliegen diesen nicht nur für die Gräfendorfer, sondern auch für die darin lebenden Tiere zu retten. Der Teich ist völlig zugewachsen. Das wollen wir ändern. Die Insel ist marode und baufällig und muss erneuert werden.",
    imgPath: "gallery/teich/IMG-20240708-WA0009.jpg",
    isProject: true,
  },
  {
    title: "Parkverschönerung",
    route: "park",
    text: "Unser schöner Park ist die Ruheoase des Dorfes, in der jeder Kraft tanken kann. Kinder toben gerne dort herum. Zum Dorffest verwandelt sich der Park in einen Veranstaltungsort, mit Bühne und Attraktionen. Zu Ostern findet dort das jährliche Eierrollen der Kinder statt. Die Bäume spenden Schatten, die Wege laden zum spazieren und verweilen ein. Es ist daher unser Anliegen den Park für alle Dorfbewohner weiterhin attraktiv zu machen.",
    imgPath: "dorfkirche.png",
    isProject: true,
  },
];

const Projects = () => {
  const renderProjects = projects.map((event) => {
    return (
      <Event
        title={event.title}
        text={event.text}
        imgPath={event.imgPath}
        // date={event.date}
        route={event.route}
        isProject={event.isProject}
      />
    );
  });
  return (
    <Layout>
      <h3>Projekte und Ziele</h3>
      <SectionContainer>
        <StyledEventContainer>{renderProjects}</StyledEventContainer>
      </SectionContainer>
    </Layout>
  );
};

export default Projects;
