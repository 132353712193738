import React from "react";
import Layout from "../../Layout/Layout";
import styled from "styled-components";
import { UnderlinedH3 } from "../../App";

const StatuteContainer = styled.div`
  line-height: 16px;
  margin-top: 40px;
  h4 {
    margin-top: 40px;
  }
  ol {
    padding-inline-start: 30px;

    @media (max-width: 650px) {
      padding-inline-start: 20px;
    }
  }
`;

const Statute = () => {
  return (
    <Layout>
      <UnderlinedH3>Satzung</UnderlinedH3>
      <StatuteContainer>
        <h4>&sect; 1 Name, Sitz</h4>
        <ol>
          <li>Der Verein führt den Namen "Heimatverein Gräfendorf"</li>
          <li>
            Er soll in das Vereinsregister eingetragen werden un führt danach
            den Zusatz "e.V." Verein führt den Namen "Heimatverein Gräfendorf"
          </li>
          <li>
            Der Sitz des Vereins ist 14913 Niederer Fläming, OT Gräfendorf
          </li>
        </ol>
        <h4>&sect; 2 Zweck und Ziel</h4>
        <ol>
          <li>
            Der Verein verfolgt ausschließlich und umittelbar gemeinnützige
            Zwecke im Sinne des Abschnitts "Steuerbegünstigte Zwecke" der
            Abgabenordnung (&sect;&sect; 51 bis 68 AO) in der jeweils gültigen
            Fassung.
          </li>
          <li>
            Zweck des Vereins ist:
            <ol type="a">
              <li>Heimat-, Landschaft- und Umweltpflege</li>
              <li>Jugendarbeit</li>
            </ol>
          </li>
          <li>
            Der Verein verwirklicht seinen Satzungszweck insbesondere durch:
            <ol type="a">
              <li>
                Fördern von Maßnahmen zur Gestaltung, Unterhaltung und
                Verschönerung des Dorfes und Durchführen von Projekten zur
                Dorfentwicklung;
              </li>
              <li>Erhalten von Natur und Umwelt in Dorf und Gemarkung;</li>
              <li>
                Pflegen und Gestalten öffentlicher Bereiche, insbesondere
                Dorfteich und Dorfpark sowie Friedhofsbereich;
              </li>
              <li>
                Verbessern von Lebensqualität und Lebensraum in Gräfendorf;
              </li>
              <li>Führen der Dorfchronik;</li>
              <li>
                Unterstützen von ortstypischen Veranstaltungen, die dem
                Satzungszwecke dienen;
              </li>
            </ol>
          </li>
          <li>
            Der Verein dient dem Gemeinwohl auf gemeinnütziger Grundlage, indem
            er sich für Erhalt und Förderung der Heimat-, Landschafts- und
            Umweltpflege einsetzt und somit das Bewusstsein aller Gräfendorfer
            Bürgerinnen und Bürger für ihren Ort fördert.
          </li>
          <li>
            Der Verein gestaltet seine Projekte nachhaltig unter Einbeziehung
            der Jugend, um Gemeinwohl und ganzheitliches Denken zu gestalten.
          </li>
          <li>Der Verein ist politisch und konfessionell neutral.</li>
        </ol>
        <h4>&sect; 3 Gemeinnützigkeit</h4>
        <ol>
          <li>
            Der Verein ist selbstlos tätig und verfolgt nicht in erster Linie
            eigenwirtschaftliche Zwecke.
          </li>
          <li>
            Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige
            Zwecke im Sinne des Abschnitts "Steuerbegünstigte Zwecke" der
            Abgabenordnung.
          </li>
          <li>
            Mittel des Vereins dürfen nur für die satzungsmäßigen Zwecke
            verwendet werden.
          </li>
          <li>
            Die Mitglieder erhalten keine Zuwendungen aus Mitteln des Vereins.
          </li>
          <li>
            Bei Aufhebung oder Auflösung des Vereins können keine
            vermögensrechtlichen Ansprüche gegen den Verein erhoben werden.
          </li>
          <li>
            Es darf keine Person durch Ausgaben, die dem Zweck der Körperschaft
            fremd sind oder durch unverhältnismäßig hohe Vergütungen begünstigt
            werden.
          </li>
        </ol>
        <h4>&sect; 4 Mitgliedschaft</h4>
        <ol>
          <li>
            Mitglied des Vereins kann jede natürliche Person und jede
            juristische Person werden.
          </li>
          <li>
            Über die Aufnahme entscheidet nach schriftlichem Antrag der
            Vorstand. Bei Minderjährigen ist der Aufnahmeantrag durch die
            gesetzlichen Vertreter zu stellen.
          </li>
          <li>
            Der Austritt aus dem Verein ist jederzeit zulässig. Er muss
            schriftlich gegenüber dem Vorstand erklärt werden. Anteilige
            Beitragserstattungen sind nicht möglich.
          </li>
          <li>
            Ein Mitglied kann aus dem Verein ausgeschlossen werden, wenn sein
            Verhalten in grober Weise gegen die Interessen des Vereins verstößt.
            Über den Ausschluss entscheidet die Mitgliederversammlung. Anteilige
            Beitragserstattungen sind nicht möglich.
          </li>
          <li>Die Mitgliedschaft endet mit dem Tod des Mitglieds.</li>
          <li>
            Das ausgetretene oder ausgeschlossene Mitglied hat keinen Anspruch
            gegenüber dem Vereinsvermögen.
          </li>
          <li>
            Die Mitglieder haben Mitgliedsbeiträge in Form von Geldbeträgen zu
            leisten. Höhe und Fälligkeit der Mitgliedsbeiträge werden durch die
            Mitgliederversammlung festgesetzt.
          </li>
        </ol>
        <h4>&sect; 5 Organe des Vereins</h4>
        Die Organe des Vereins sind:
        <ol>
          <li>die Mitgliederversammlung</li>
          <li>der Vorstand</li>
        </ol>
        <h4>&sect; 6 Mitgliederversammlung</h4>
        <ol>
          <li>
            Die ordentliche Mitgliederversammlung findet einmal jährlich statt.
            Außerdem muss eine Mitgliederversammlung einberufen werden, wenn das
            Interesse des Vereins es erfordert oder wenn mindestens 2/10 der
            Mitglieder die Einberufung schriftlich unter Angabe des Zwecks und
            der Gründe verlangt.
          </li>
          <li>
            Jede Mitgliederversammlung ist vom Vorstand schriftlich unter
            Einhaltung einer Einladungsfrist von zwei Wochen und unter Angabe
            der Tagesordnung einzuberufen.
          </li>
          <li>
            Versammlungsleiter ist der/die 1. Vorsitzende und im Falle seiner
            Verhinderung der/die 2. Vorsitzende. Sollten beide nicht anwesend
            sein, wird ein Versammlungsleiter von der Mitgliederversammlung
            gewählt. Soweit der Schriftführer nicht anwesend ist, wird auch
            dieser von der Mitgliederversammlung bestimmt.
          </li>
          <li>
            Jede ordnungsgemäß einberufene Mitgliederversammlung ist ohne
            Rücksicht auf die Zahl der erschienenen Mitglieder beschlussfähig.
          </li>
          <li>
            Die Beschlüsse der Mitgliederversammlung werden mit einfacher
            Mehrheit der abgegebenen gültigen Stimmen gefasst. Zur Änderung der
            Satzung und des Vereinszwecks ist jedoch eine Mehrheit von 3/4 der
            abgegebenen gültigen Stimmen erforderlich.
          </li>
          <li>
            Über die Beschlüsse der Mitgliederversammlung ist ein Protokoll
            aufzunehmen, welches vom Versammlungsleiter und dem Schriftführer zu
            unterschreiben ist.
          </li>
        </ol>
        <h4>&sect; 7 Vorstand</h4>
        <ol>
          <li>
            Der Gesamtvorstand des Vereins besteht aus dem/der 1. Vorsitzenden,
            dem/der 2. Vorsitzenden, dem/der Kassierer/in, dem/der
            Schriftführer/in und einem Beisitzer.
          </li>
          <li>
            Der Vorstand im Sinne des &sect; 26 BGB besteht aus dem 1.
            Vorsitzenden, dem 2. Vorsitzenden und dem Kassierer. Mindestens zwei
            von den drei Genannten vertreten den Verein gemeinsam
            (Vier-Augen-Prinzip).
          </li>
          <li>
            Der Vorstand wird von der Mitgliederversammlung auf die Dauer von
            drei Jahren gewählt; er bleibt jedoch so lange im Amt bis eine
            Neuwahl erfolgt ist.
          </li>
        </ol>
        <h4>&sect; 8 Auflösung, Anfall des Vereinsvermögens</h4>
        <ol>
          <li>
            Zur Auflösung des Vereins ist eine Mehrheit von 8/10 der abgegebenen
            gültigen Stimmen erforderlich.
          </li>
          <li>
            Bei Auflösung des Vereins, Entzugs der Rechtsfähigkeit oder bei
            Wegfall steuerbegünstigter Zwecke fällt das Vermögen des Vereins an
            die Gemeinde Niederer Fläming zwecks ausschließlicher und
            unmittelbarer Verwendung für einen gemeinnützigen Zweck in
            Gräfendorf zu.
          </li>
        </ol>
        <div style={{ marginTop: "40px" }}>
          <p style={{ margin: "4px 0" }}>Datum der Gründung: 17.12.2022</p>
          <p style={{ margin: "0" }}>
            Datum 1. Änderung (fortgesetzte Gründungsversammlung) 09.08.2023
          </p>
        </div>
      </StatuteContainer>
    </Layout>
  );
};

export default Statute;
