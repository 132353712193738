import React from "react";
import Layout from "../Layout/Layout";

const Privacy = () => {
  return (
    <Layout>
      <h3>Rechtliche Hinweise</h3>
      <p>
        Inhalt und Aufbau dieser Seiten sind urheberrechtlich geschützt. Die
        Verwendung von Texten, Textteilen und Bildmaterial, abgesehen von dem
        Abruf zur privaten Nutzung, bedarf der vorherigen schriftlichen
        Zustimmung. Aus urheberrechtlichen Gründen dürfen grafische Elemente
        ohne vorherige Genehmigung nicht kopiert oder weiterverwendet werden.
      </p>
      <p>
        Da wir auf unseren Seiten Links zu anderen Seiten im Internet führen,
        betonen wir ausdrücklich, dass wir keinerlei Einfluss auf die Gestaltung
        und die Inhalte der gelinkten Seiten haben. Deshalb distanzieren wir uns
        hiermit ausdrücklich von allen Inhalten aller gelinkten Seiten, die über
        unsere Internetseite "graefendorf-heimatverein.de" zu erreichen sind.
        Wir machen uns deren Inhalte nicht zu Eigen. Diese Erklärung gilt für
        alle auf unseren Seiten angebrachten Links.
      </p>
      <p>
        Die Internet-Seiten wurden mit der notwendigen Sorgfalt und nach bestem
        Wissen erstellt. Sie werden regelmäßig überprüft und aktualisiert. Wir
        können jedoch keine Gewähr für die absolute Korrektheit, die permanente
        Aktualität sowie die Vollständigkeit dieser Web-Seiten übernehmen.
      </p>
    </Layout>
  );
};

export default Privacy;
