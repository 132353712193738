const CloseIcon = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59 8.59c-.29.29-.29.77 0 1.06L11.47 12 8.59 14.88c-.29.29-.29.77 0 1.06.29.29.77.29 1.06 0L12 13.06l2.88 2.88c.29.29.77.29 1.06 0 .29-.29.29-.77 0-1.06L13.53 12l2.88-2.88c.29-.29.29-.77 0-1.06-.29-.29-.77-.29-1.06 0L12 10.94 9.12 8.06c-.29-.29-.77-.29-1.06 0z"
        fill="#999"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5zM12 21C6.92 21 3 17.08 3 12S6.92 3 12 3 21 6.92 21 12 17.08 21 12 21z"
        fill="#999"
      />
    </svg>
  );
};
export default CloseIcon;
