import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import styled from "styled-components";
import MenuIcon from "../../components/MenuIcon";
import Logo from "../../components/Logo";
import { Link, useLocation } from "react-router-dom";

const HeaderContainer = styled.div`
  width: 100%;
  position: relative;
  border-top: 15px solid rgb(78, 150, 169);
  box-shadow: 5px 7px 5px #aaa;
  border-radius: 6px 6px 0 0;

  @media (max-width: 650px) {
    border-radius: 0;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 450px;

  @media (max-width: 650px) {
    height: 250px;
  }
`;

const TitleImage = styled.img`
  background-image: url("schloss-graefendorf-paint.jpg");
  width: 100%;
  height: 100%;
  background-position: center;

  @media (max-width: 650px) {
    background-size: cover;
  }
`;

const MenuButton = styled.div`
  border: 3px solid #999;
  border-radius: 8px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  display: flex;
  margin: 30px;

  svg {
    stroke: #777;
    fill: #777;
    // height: 100%;
    width: 60px;
    height: 60px;
  }

  &:hover {
    border-color: #555;
    svg {
      stroke: #333;
      fill: #333;
    }
  }

  @media (max-width: 650px) {
    width: 34px;
    height: 34px;
    border: 2px solid #999;
    margin: 20px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
`;

export const CopyrightLink = styled.a`
  position: absolute;
  bottom: 55px;
  left: 10px;
  color: #333;
  //max-width: 300px;
  text-align: left;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.8);
  padding: 4px 6px;
  font-size: 12px;

  @media (max-width: 650px) {
    bottom: 5px;
    left: 5px;
    font-size: 10px;
  }
`;

const HeaderTopPart = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 650px) {
    justify-content: space-between;
    height: 80px;
  }
`;
const LogoContainer = styled.div`
  width: 200px;
  height: auto;
  margin: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 650px) {
    width: 130px;
    height: auto;
  }

  svg {
    width: 100%;
    height: auto;

    tspan {
      font-family: "CustomBrushScriptFont", sans-serif !important;
    }
  }
`;

const debounce = (fn: Function, ms = 100) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

const Header = () => {
  const rootWidth = document?.getElementById("root")?.offsetWidth;
  const [isMobileView, setIsMobileView] = useState(rootWidth ? rootWidth <= 650 : false);
  const [clickedMenuOpen, setClickedMenuOpen] = useState(false);
  const location = useLocation();
  const pathPrefix =
    location.pathname.includes("veranstaltungen/") ||
    location.pathname.includes("projekte/") ||
    location.pathname.includes("bildergalerie/")
      ? "../"
      : "";

  const updateState = () => {
    setIsMobileView(rootWidth ? rootWidth <= 650 : false);
  };
  const debouncedUpdate = debounce(updateState);

  useEffect(() => {
    window.addEventListener("resize", debouncedUpdate);

    return () => window.removeEventListener("resize", debouncedUpdate);
  }, [debouncedUpdate]);

  return (
    <HeaderContainer>
      <HeaderTopPart>
        <LogoContainer>
          <Link to="/">
            <Logo />
          </Link>
        </LogoContainer>
        {isMobileView && (
          <MenuButton onClick={() => setClickedMenuOpen(!clickedMenuOpen)}>
            <MenuIcon />
          </MenuButton>
        )}
        <Navbar
          clickedMenuOpen={clickedMenuOpen}
          isMobileView={isMobileView}
          setClickedMenuOpen={setClickedMenuOpen}
        />
      </HeaderTopPart>
      <ImageContainer>
        <div className="slideshow">
          {/*<div style={{ }}>*/}
          <div
            className="slide"
            style={{
              backgroundImage: `url(${pathPrefix}schloss-graefendorf-paint.jpg)`,
              zIndex: 51,
            }}
            title="Schloss Gräfendorf"
          >
            <CopyrightLink
              href="https://commons.wikimedia.org/wiki/File:Schloss_Gr%C3%A4fendorf_Sammlung_Duncker.jpg"
              target="_blank"
            >
              &copy; Theodor Hennicke, Alexander Duncker, via Wikimedia Commons
            </CopyrightLink>
          </div>

          <div
            className="slide"
            style={{
              backgroundImage: `url(${pathPrefix}dorfkirche.png)`,
            }}
            title="Dorfkirche Gräfendorf-Ostseite"
          ></div>
          <div
            className="slide"
            style={{
              backgroundImage: `url(${pathPrefix}feuerwehr.png)`,
            }}
          ></div>
        </div>
      </ImageContainer>
    </HeaderContainer>
  );
};

export default Header;
