import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 16px;
  border-radius: 5px;
  //margin-bottom: 30px;

  @media (max-width: 650px) {
    padding: 20px 10px;
  }
`;

const CalendarIcon = styled.div`
  font-size: 9px;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  margin-right: 30px;

  @media (max-width: 750px) {
    margin-right: 20px;
  }

  @media (max-width: 650px) {
    width: 40px;
    height: 40px;
  }
`;

const CalendarTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  h4 {
    font-size: 18px;
    padding: 0;
    margin: 8px 0 4px 0;

    @media (max-width: 750px) {
      font-size: 17px;
    }
    @media (max-width: 650px) {
      font-size: 16px;
    }
  }
`;

const CalendarSub = styled.div`
  flex-direction: column;
  display: flex;
`;

const Weekday = styled.div`
  font-weight: bold;
  width: 50px;
  text-align: center;
  font-size: 18px;
  margin-bottom: 4px;

  @media (max-width: 750px) {
    font-size: 17px;
  }

  @media (max-width: 650px) {
    width: 40px;
    font-size: 16px;
    margin-bottom: 2px;
  }
`;
const OrganizerWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 40px;
  align-items: flex-start;
  padding: 8px 0;

  @media (max-width: 850px) {
    font-size: 15px;
  }
  @media (max-width: 800px) {
    font-size: 14px;
  }
  @media (max-width: 650px) {
    font-size: 14px;
  }
`;

const OrganizerLabel = styled.span`
  text-decoration: underline;
  width: 75px !important;
  display: inline-block;

  @media (max-width: 750px) {
    width: 65px !important;
  }

  @media (max-width: 650px) {
    min-width: 55px !important;
  }
`;

const OrganizerValue = styled.span`
  font-style: italic;
  margin-left: 5px;
  display: inline-block;
`;

const DateMonth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40%;
  top: 0;
  color: #fff;
  background-color: rgba(100, 189, 210, 1);
  box-shadow: 0 0 0 #448448;
  font-size: 16px;

  @media (max-width: 850px) {
    font-size: 14px;
  }
`;

const Span = styled.span`
  height: 60%;
  letter-spacing: -0.05em;
  color: #2f2f2f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  text-align: center;

  @media (max-width: 850px) {
    font-size: 17px;
  }
`;

type TEventProps = {
  title: string;
  date: string;
  time?: string;
  location: string;
  organizer?: string;
  weekday?: string;
};

const months = {
  "1": "Jan",
  "2": "Feb",
  "3": "Mär",
  "4": "Apr",
  "5": "Mai",
  "6": "Jun",
  "7": "Jul",
  "8": "Aug",
  "9": "Sep",
  "10": "Okt",
  "11": "Nov",
  "12": "Dez",
};

type monthKeys =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

const EventSimple = ({
  title,
  date,
  time,
  location,
  organizer,
  weekday,
}: TEventProps) => {
  const month = date?.split(".")?.[1];
  const day = date?.split(".")?.[0];

  return (
    <Container>
      <CalendarTitleContainer>
        <CalendarSub>
          <Weekday>{weekday}</Weekday>
          {date && (
            <CalendarIcon>
              <DateMonth>{months[month as monthKeys]}</DateMonth>
              <Span>{day}</Span>
            </CalendarIcon>
          )}
        </CalendarSub>
        <h4>{title}</h4>
      </CalendarTitleContainer>
      <OrganizerWrapper>
        <OrganizerLabel>Wo:</OrganizerLabel>
        <OrganizerValue>{location}</OrganizerValue>
      </OrganizerWrapper>

      {time && (
        <OrganizerWrapper>
          <OrganizerLabel>Wann:</OrganizerLabel>
          <OrganizerValue>{time}</OrganizerValue>
        </OrganizerWrapper>
      )}
      {organizer && (
        <OrganizerWrapper>
          <OrganizerLabel>Organisator:</OrganizerLabel>
          <OrganizerValue>{organizer}</OrganizerValue>
        </OrganizerWrapper>
      )}
    </Container>
  );
};

export default EventSimple;
