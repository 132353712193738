import React from "react";
import Layout from "../../Layout/Layout";
import styled from "styled-components";

const ImgContainer = styled.div`
  text-align: left;
`;
const StyledImg = styled.img`
  width: 100%;
  object-fit: fill; /* cover */
  height: auto;
  max-width: 400px;
`;

const Teich = () => {
  return (
    <Layout>
      <h3>Rettung des Dorfteiches</h3>
      <ImgContainer>
        <StyledImg src="gallery/teich/IMG-20240708-WA0009.jpg" />
      </ImgContainer>
      <p>
        Mitten im Dorf gelegen befindet sich unser Dorfteich. Neben vielen
        unvergesslichen Sonnentuntergängen, die er uns beschert, ist er auch ein
        Zuhause für Enten, Fische und Frösche. Leider trocknet unser Teich vom
        Jahr zu Jahr immer mehr aus. Es ist uns ein Herzensanliegen diesen nicht
        nur für die Gräfendorfer, sondern auch für die darin lebenden Tiere zu
        retten. Der Teich ist völlig zugewachsen. Das wollen wir ändern. Die
        Insel ist marode und baufällig und muss erneuert werden.
      </p>
    </Layout>
  );
};

export default Teich;
