import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const FooterContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1%, rgba(100, 189, 210, 0.3)),
    color-stop(100%, rgba(56, 111, 128, 0.3))
  );
  border-radius: 0 0 6px 6px;
  position: absolute;
  bottom: 0;

  @media (min-width: 651px) {
    margin-top: 30px;
  }

  a {
    text-decoration: none;
    font-size: 16px;
    color: black;

    @media (max-width: 650px) {
      font-size: 14px;
    }
  }

  @media (max-width: 650px) {
    border-radius: 0;
    height: 45px;
  }
`;

const NavbarContainer = styled.div``;

const routes = [
  { title: "Impressum", path: "/impressum" },
  { title: "Datenschutz", path: "/datenschutz" },
];

const Footer = () => {
  const navigate = useNavigate();
  const links = routes.map((route) => (
    <Link to={route.path}>{route.title}</Link>
  ));

  return <FooterContainer>{links}</FooterContainer>;
};

export default Footer;
