import * as React from "react";
const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={495.821}
    height={220.237}
    viewBox="0 0 127.186 63.271"
  >
    <defs>
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={3.141}
        x2={20.859}
        y1={10.11}
        y2={10.11}
        gradientTransform="matrix(1.65689 0 0 2.14229 430.24 329.313)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient id="a">
        <stop
          offset={0}
          style={{
            stopColor: "#fff",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#fff",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <linearGradient
        xlinkHref="#b"
        id="d"
        x1={213.511}
        x2={260.192}
        y1={349.427}
        y2={349.427}
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient id="b">
        <stop
          offset={0}
          style={{
            stopColor: "#000",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#000",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <linearGradient
        xlinkHref="#b"
        id="f"
        x1={213.511}
        x2={260.192}
        y1={349.427}
        y2={349.427}
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#b"
        id="e"
        x1={213.511}
        x2={260.192}
        y1={349.427}
        y2={349.427}
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#b"
        id="g"
        x1={213.511}
        x2={260.192}
        y1={349.427}
        y2={349.427}
        gradientUnits="userSpaceOnUse"
      />
      <filter id="h" width={1.282} height={1.381} x={-0.117} y={-0.158}>
        <feGaussianBlur in="SourceAlpha" stdDeviation={2} />
        <feOffset dx={3} dy={3} result="offsetblur" />
        <feFlood floodColor="rgba(0,0,0,0.5)" />
        <feComposite in2="offsetblur" operator="in" />
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>

      <filter id="h2" width={1.182} height={1.181} x={-0.117} y={-0.158}>
        <feGaussianBlur in="SourceAlpha" stdDeviation={2} />
        <feOffset dx={1} dy={1} result="offsetblur" />
        <feFlood floodColor="rgba(0,0,0,0.5)" />
        <feComposite in2="offsetblur" operator="in" />
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      d="M462.798 347.886c0-1.692-1.061-3.063-2.37-3.063-1.321.012-2.395-1.376-2.386-3.085 0-2.33-.668-4.456-1.77-6.078-1.306-1.921-3.22-3.134-5.354-3.134h-1.657c-3.935 0-7.125 4.124-7.125 9.212.01 1.675-1.024 3.05-2.32 3.085-1.308 0-2.369 1.371-2.369 3.063.003 1.834.91 3.462 2.254 4.05l.613.278c1.13.481 1.891 1.847 1.889 3.385 0 1.972-1.242 3.568-2.767 3.556-1.704.016-2.868 2.23-2.254 4.284 1.108 3.61 3.807 5.979 6.81 5.977h12.261c2.999.005 5.697-2.355 6.81-5.955.614-2.054-.55-4.268-2.253-4.285-1.526.012-2.768-1.584-2.767-3.556-.002-1.538.759-2.903 1.888-3.385l.613-.278c1.35-.59 2.257-2.229 2.254-4.07z"
      className="cls-1"
      style={{
        fill: "url(#c)",
        fillOpacity: 1,
        stroke: "#036",
        strokeWidth: 2.73594,
        strokeMiterlimit: 2.9,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="translate(-60.535 -89.169) scale(.33731)"
    />
    <path
      d="M450.201 342.547v36.89"
      className="cls-1"
      style={{
        stroke: "#036",
        strokeWidth: 2.69438,
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="translate(-60.535 -89.169) scale(.33731)"
    />
    <path
      d="m445.37 347.797 4.329 3.604"
      className="cls-1"
      style={{
        stroke: "#036",
        strokeWidth: 2.31473,
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="translate(-60.535 -89.169) scale(.33731)"
    />
    <path
      d="m444.518 357.235 5.753 4.278M449.669 356.089l6.151-3.326"
      className="cls-1"
      style={{
        stroke: "#036",
        strokeWidth: 2.34375,
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="translate(-60.535 -89.169) scale(.33731)"
    />
    <path
      d="M430.671 355.251h8.455v25.987h-8.455z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "#fff",
        strokeWidth: 3.21201,
        strokeMiterlimit: 2.9,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="translate(-60.535 -89.169) scale(.33731)"
    />
    <path
      d="M440.781 203.438a104.437 104.437 0 0 0 1.781-19.125 106.19 106.19 0 0 0-71.813-100.625C354.859 33.969 308.953 0 256 0c-52.953 0-98.875 33.969-114.75 83.688-42.734 14.625-71.813 54.75-71.813 100.625 0 6.344.594 12.75 1.766 19.125-24.813 22.813-38.844 54.547-38.844 88.531 0 66.516 54.109 120.625 120.625 120.625 13.219 0 26.125-2.125 38.531-6.313 14.422 10.219 31.078 16.828 48.484 19.359V512h32v-86.359c17.406-2.531 34.063-9.141 48.484-19.359 12.391 4.188 25.313 6.313 38.531 6.313 66.516 0 120.625-54.109 120.625-120.625.002-33.986-14.045-65.72-38.858-88.532zm-81.765 177.156c-12.094 0-23.828-2.406-34.922-7.156L315 369.531l-7.563 6.406c-12.313 10.438-27.516 16.844-43.438 18.469v-41.875l62.547-71.469-12.046-10.531L264 328.25v-58.938l50.438-57.656-12.047-10.531L264 245v-90.344h-16v90.359l-38.406-43.891-12.047 10.531L248 269.313v58.938l-50.5-57.719-12.047 10.531L248 352.531v41.875a79.817 79.817 0 0 1-43.453-18.469L197 369.531l-9.109 3.906c-11.078 4.75-22.828 7.156-34.906 7.156-48.875 0-88.625-39.75-88.625-88.625 0-27.516 12.563-53.031 34.453-70l8.563-6.656-2.984-10.406c-1.969-6.844-2.953-13.781-2.953-20.594a74.267 74.267 0 0 1 56.656-72.266l9.5-2.344 2.25-9.516C179.344 60.031 214.766 32 256 32s76.656 28.031 86.141 68.188l2.25 9.516 9.5 2.344c33.359 8.203 56.672 37.922 56.672 72.266 0 6.813-1 13.75-2.969 20.594l-2.984 10.406 8.563 6.656c21.906 16.969 34.469 42.484 34.469 70-.001 48.874-39.767 88.624-88.626 88.624z"
      className="st0"
      style={{
        fill: "#036",
        fillOpacity: 1,
      }}
      transform="matrix(.03007 0 0 .03944 58.83 18.641)"
    />
    <path
      d="M365.482 351.901h5.757v8.846h-5.757z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "#000",
        strokeWidth: 0,
      }}
      transform="translate(-60.535 -89.169) scale(.33731)"
    />
    <path
      d="M354.764 346.797h13.419v29.717h-13.419z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "#000",
        strokeWidth: 0,
      }}
      transform="translate(-60.535 -89.169) scale(.33731)"
    />
    <path
      d="M365.282 361.317h6.338v9.102h-6.338zM357.69 338.123h5.757v8.846h-5.757zM380.474 350.553h19.077v17.249h-19.077z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "#000",
        strokeWidth: 0,
      }}
      transform="translate(-60.535 -89.169) scale(.33731)"
    />
    <g
      style={{
        stroke: "url(#d)",
        strokeOpacity: 1,
      }}
      transform="translate(-49.583 -89.34) scale(.33731)"
    >
      <g
        style={{
          stroke: "url(#e)",
          strokeOpacity: 1,
        }}
      >
        <path
          d="m461.75 447.5-83.703-125.344h46.75l-84.141-126.047h46.281L256 0 125.047 196.109h46.281l-84.14 126.047h46.75L50.25 447.5h189.625V512H272.126v-64.5ZM176.047 299.938h-46.734l84.125-126.016h-46.281L256 40.875l88.828 133.047h-46.297l84.156 126.016h-46.75l83.703 125.359H264.063v-37.391l63.047-72.031-12.141-10.625-50.906 58.188v-59.406l50.844-58.109-12.141-10.609-38.703 44.234V171.469h-16.125v108.063l-38.703-44.219-12.141 10.609 50.844 58.109v59.391l-50.906-58.172-12.125 10.625 63.031 72.031v37.391H92.359Z"
          className="st0"
          style={{
            fill: "#036",
            fillOpacity: 1,
            stroke: "url(#f)",
            strokeOpacity: 1,
          }}
          transform="matrix(.10698 0 0 .12118 208.236 318.196)"
        />
      </g>
      <path
        stroke="none"
        d="M249.922 335.226h10.27v45.541h-10.27z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "url(#g)",
          strokeWidth: 0,
          strokeOpacity: 1,
        }}
      />
    </g>
    <g
      style={{
        fill: "#036",
        filter: "url(#h)",
      }}
      transform="matrix(.06315 0 0 .06549 33.848 5.288)"
    >
      <path
        d="M390.305 235.134H237.759v-12.689l-93.676-107.348V72.594h35.682V33.46h-35.682V0h-39.142v33.46H69.26v39.134h35.681v42.518L11.265 222.444V512h93.782v-88.388c.03-10.748 8.732-19.45 19.473-19.465 10.741.015 19.442 8.717 19.457 19.465V512h356.757V361.667Zm-164.918 52.805v211.682h-69.038v-76.009c0-17.584-14.246-31.837-31.829-31.837-17.584 0-31.845 14.253-31.845 31.837v76.009H23.637V227.09l93.676-107.34V60.214H81.632V45.833l35.681.008V12.372h14.397V45.84h35.682v14.374H131.71v59.528l93.676 107.348v20.417h159.299L480.93 357.8H237.759v-69.86h-12.372Zm262.975 211.682H237.759V370.18h250.604v129.441z"
        className="st0"
        style={{
          fill: "#036",
          fillOpacity: 1,
        }}
      />
      <path
        d="M349.351 460.676v-52.601h-51.045v58.787h51.045zm-12.372-6.194h-26.3v-34.035h26.3zM430.564 460.676v-52.601h-51.045v58.787h51.045zm-12.372-6.194h-26.301v-34.035h26.301zM97.493 305.274h54.913v-54.912H97.493Zm12.374-42.54h30.167v30.168h-30.167z"
        className="st0"
        style={{
          fill: "#036",
        }}
      />
    </g>
    <path
      d="M392.382 345.469h5.873v8.496h-5.873z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "#000",
        strokeWidth: 0,
      }}
      transform="translate(-60.535 -89.169) scale(.33731)"
    />
    <g
      style={{
        fill: "#036",
        strokeWidth: 247.453,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        filter: "url(#h2)",
      }}
      transform="matrix(.33731 0 0 .32793 7.068 -85.66)"
    >
      <path
        fill="var(--ci-primary-color, #003366)"
        d="M211.4 334.879c-1.847-1.203-3.722-1.22-5.496.037l-26.93 18.47v26.239h15.422v-15.289h8.812v15.289h37.451v-26.316zm27.076 42.39-32.786-.019-.021-15.313-13.764.004-.262 15.285-10.049-.064.14-22.445 26.831-18.466.233.146 29.534 18.487z"
        className="ci-primary"
        style={{
          display: "inline",
          mixBlendMode: "normal",
          fill: "#036",
          fillOpacity: 1,
          strokeWidth: 247.453,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
      />
    </g>
    <text
      xmlSpace="preserve"
      x={173.827}
      y={425.818}
      style={{
        fontSize: "31.3756px",
        lineHeight: 1.25,
        fontFamily: "KacstArt",
        // InkscapeFontSpecification: "KacstArt",
        strokeWidth: 0.784389,
      }}
      transform="translate(-59.673 -85.512) scale(.33731)"
    >
      <tspan
        x={150.827}
        y={425}
        style={{
          fill: "#036",
          fillOpacity: 1,
          strokeWidth: 0.784389,
          fontSize: "50px",
        }}
      >
        {"Heimatverein Gr\xE4fendorf"}
      </tspan>
    </text>
  </svg>
);
export default Logo;
