import React from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { CopyrightLink } from "../Layout/Header/Header";

const Container = styled.div`
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 30px;

  @media (max-width: 650px) {
    padding: 30px;
  }
  @media (max-width: 450px) {
    padding: 25px;
  }
  @media (max-width: 350px) {
    padding: 20px;
  }
`;

const CalendarIcon = styled.div`
  font-size: 9px;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  margin-right: 30px;

  @media (max-width: 750px) {
    margin-right: 20px;
  }

  @media (max-width: 650px) {
    width: 40px;
    height: 40px;
  }
`;

const ImgContainer = styled.div`
  position: relative;
`;

const CalendarTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  h4 {
    font-size: 20px;
    padding: 0;
    margin: 8px 0 4px 0;

    @media (max-width: 750px) {
      font-size: 18px;
    }
    @media (max-width: 650px) {
      font-size: 16px;
    }
  }
`;

const OrganizerWrapper = styled.span`
  color: #555;
  margin-top: 10px;
  line-height: 20px;
  display: block;

  @media (max-width: 850px) {
    font-size: 15px;
  }
  @media (max-width: 800px) {
    font-size: 14px;
  }
  @media (max-width: 650px) {
    font-size: 14px;
  }
`;

const OrganizerLabel = styled.span`
  text-decoration: underline;
`;

const OrganizerValue = styled.span`
  font-style: italic;
  margin-left: 5px;
`;

const StyledImg = styled.img`
  width: 100%;
  object-fit: cover; /* cover */
  height: 230px;

  @media (max-width: 850px) {
    height: 210px;
  }
  @media (max-width: 800px) {
    height: 200px;
  }
  @media (max-width: 750px) {
    height: 190px;
  }
  @media (max-width: 700px) {
    height: 180px;
  }
  @media (max-width: 650px) {
    height: 300px;
  }
  @media (max-width: 550px) {
    height: 280px;
  }
  @media (max-width: 510px) {
    height: 265px;
  }
  @media (max-width: 480px) {
    height: 250px;
  }
  @media (max-width: 440px) {
    height: 220px;
  }
  @media (max-width: 400px) {
    height: 200px;
  }
  @media (max-width: 380px) {
    height: 180px;
  }
  @media (max-width: 340px) {
    height: 160px;
  }
`;

const DateMonth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40%;
  top: 0;
  color: #fff;
  background-color: rgba(100, 189, 210, 1);
  box-shadow: 0 0 0 #448448;
  font-size: 16px;

  @media (max-width: 850px) {
    font-size: 14px;
  }
`;

const Span = styled.span`
  height: 60%;
  letter-spacing: -0.05em;
  color: #2f2f2f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  text-align: center;

  @media (max-width: 850px) {
    font-size: 17px;
  }
`;

const StyleParagraph = styled.p`
  max-height: 100px;
  display: -webkit-box;
  max-width: max-content;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 26px;
  margin-top: 26px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ReadMoreButton = styled.div`
  color: rgb(34, 38, 42);
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;

  &:hover {
    background-color: rgba(100, 189, 210, 0.2);
    transition: 0.3s;
    cursor: pointer;
  }
`;

type TEventProps = {
  title: string;
  text: string;
  imgPath: string;
  date?: string;
  route: string;
  organizer?: string;
  isProject?: boolean;
  copyright?: string;
};

const months = {
  "1": "Jan",
  "2": "Feb",
  "3": "Mär",
  "4": "Apr",
  "5": "Mai",
  "6": "Jun",
  "7": "Jul",
  "8": "Aug",
  "9": "Sep",
  "10": "Okt",
  "11": "Nov",
  "12": "Dez",
};

type monthKeys =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

const Event = ({
  title,
  text,
  imgPath,
  date,
  organizer,
  route,
  isProject,
  copyright,
}: TEventProps) => {
  const month = date?.split(".")?.[1];
  const day = date?.split(".")?.[0];

  return (
    <Container>
      <ImgContainer>
        <StyledImg src={imgPath} />
        {copyright && (
          <CopyrightLink
            style={{
              bottom: "15px",
              height: "18px",
              display: "flex",
              alignItems: "center",
            }}
          >
            &copy; {copyright}
          </CopyrightLink>
        )}
      </ImgContainer>
      <CalendarTitleContainer>
        {date && (
          <CalendarIcon>
            <DateMonth>{months[month as monthKeys]}</DateMonth>
            <Span>{day}</Span>
          </CalendarIcon>
        )}
        <h4>{title}</h4>
      </CalendarTitleContainer>

      {organizer && (
        <OrganizerWrapper>
          <OrganizerLabel>Organisator</OrganizerLabel>:
          <OrganizerValue>{organizer}</OrganizerValue>
        </OrganizerWrapper>
      )}

      <StyleParagraph
        style={{
          lineHeight: isProject === true ? "22px" : "26px",
          marginTop: isProject === true ? "16px" : "26px",
        }}
      >
        {text}
      </StyleParagraph>
      <StyledLink to={route}>
        <ReadMoreButton>Weiterlesen</ReadMoreButton>
      </StyledLink>
    </Container>
  );
};

export default Event;
