import React from "react";
import Layout from "../../Layout/Layout";
import { SectionContainer, StyledEventContainer, StyledH3 } from "../Home";
import Event from "../../components/Event";
import { UnderlinedH3 } from "../../App";

const pastEvents = [
  {
    title: "Teichfest",
    organizer: "Erika Wolschke",
    route: "26-5",
    text: "Am 26.05.2024 fand das jährliche Teichfest statt. Vorne auf dem Rasen, bei Elvira und Frank Schlanke, werden Zelte, Tische und Bänke aufgestellt. Versorgung mit Essen und Getränken übernimmt Schirrmi's Allerlei. Zusätzlich gibt es Kuchen und Torten, versorgt durch unsere fleißigen Dorfbewohner. Für musikalische Unterhaltung ist gesorgt: u.a. unser Dorfchor, geführt von Erika Wolschke, sowie ein DJ. Zukünftig wird das Teichfest vom Heimatverein ausgeführt.",
    imgPath: "gallery/teich/IMG-20240708-WA0009.jpg",
    date: "26.5.",
    copyright: "Sabrina Möhle",
  },
  {
    title: "Osterfeuer",
    organizer: "Heimatverein Gräfendorf",
    route: "19-4",
    text: "Am 30.03.2024 findet das Osterfeuer statt. Bringt eure trockenen Äste und genießt das Feuer in einer gemütlichen Runde! Versorgung mit Essen und Getränken übernimmt Schirrmi's Allerlei.",
    imgPath: "osterfeuer.png",
    date: "30.3.",
  },
  {
    title: "Knutfest",
    organizer: "Heimatverein Gräfendorf",
    route: "13-1",
    text: "Am 13.01.2024 findet das Knutfest (Weihnachtsbaumverbrennen) statt. Bringt eure Weihnachtsbäume und lasst uns gemeinsam das Ende der Weihnachtszeit markieren und die langsame Wiederkehr des Tageslichts begrüßen. Für Essen und Getränke sorgt Heimatverein e.V.",
    imgPath: "weihnachtsbaumverbrennen.jpg",
    date: "13.1.",
  },
];

const PastEvents = () => {
  const renderPastEvents = pastEvents.map((event) => {
    return (
      <Event
        title={event.title}
        text={event.text}
        imgPath={event.imgPath}
        date={event.date}
        route={event.route}
        organizer={event.organizer}
        copyright={event.copyright}
      />
    );
  });

  return (
    <Layout>
      <SectionContainer>
        <UnderlinedH3>
          Vergangene Veranstaltungen des H.V. Gräfendorf
        </UnderlinedH3>
        <StyledEventContainer>{renderPastEvents}</StyledEventContainer>
      </SectionContainer>
    </Layout>
  );
};

export default PastEvents;
