const MenuIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 91 91"
      height="91px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 91 91"
      width="91px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect height="3.4" width="30.602" x="36.891" y="31.362" />
        <rect height="3.4" width="30.602" x="36.891" y="44.962" />
        <rect height="3.4" width="30.602" x="36.891" y="58.562" />
        <path d="M27.594,36.462c1.877,0,3.398-1.522,3.398-3.4c0-1.877-1.521-3.399-3.398-3.399h-0.02c-1.877,0-3.391,1.522-3.391,3.399   C24.184,34.939,25.715,36.462,27.594,36.462z" />
        <path d="M27.594,50.062c1.877,0,3.398-1.521,3.398-3.399s-1.521-3.399-3.398-3.399h-0.02c-1.877,0-3.391,1.521-3.391,3.399   S25.715,50.062,27.594,50.062z" />
        <path d="M27.594,63.662c1.877,0,3.398-1.522,3.398-3.399c0-1.879-1.521-3.4-3.398-3.4h-0.02c-1.877,0-3.391,1.521-3.391,3.4   C24.184,62.14,25.715,63.662,27.594,63.662z" />
      </g>
    </svg>
  );
};
export default MenuIcon;
