import React from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
import EmailIcon from "../components/EmailIcon";
import PhoneIcon from "../components/PhoneIcon";
import LocationPinIcon from "../components/LocationPinIcon";
import { UnderlinedH3 } from "../App";

export const IconContainer = styled.div`
  width: 30px;
  height: 30px;

  svg {
    height: 30px;
    width: 30px;
    * {
      stroke: rgb(0, 51, 102);
    }
  }
`;

const Entry = styled.div`
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
`;

const Block = styled.div``;
const Left = styled.div`
  min-width: 70px;
`;

const Contact = () => {
  return (
    <Layout>
      <UnderlinedH3>Kontakt</UnderlinedH3>
      <Entry>
        <Left>
          <IconContainer>
            <EmailIcon />
          </IconContainer>
        </Left>
        <span>info@heimatverein-graefendorf.de</span>
      </Entry>
      {/*<Entry>*/}
      {/*  <Left>*/}
      {/*    <IconContainer>*/}
      {/*      <PhoneIcon />*/}
      {/*    </IconContainer>*/}
      {/*  </Left>*/}
      {/*  <span>+49 30 123 4567</span>*/}
      {/*</Entry>*/}
      <Entry
        style={{
          height: "max-content",
          display: "flex",
          alignItems: "flex-start",
          marginTop: "10px",
        }}
      >
        <Left>
          <IconContainer>
            <LocationPinIcon className="fillSvgPath" />
          </IconContainer>
        </Left>
        <Block>
          <Left></Left>
          <span>Gräfendorf Dorfstraße 23</span>
          <Left></Left>
          <span>14913 Niederer Fläming</span>
          <Left></Left>
          <span>Deutschland</span>
        </Block>
      </Entry>
    </Layout>
  );
};

export default Contact;
