import React from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
import EventSimple from "../components/EventSimple";
import { UnderlinedH3 } from "../App";

export const StyledH3 = styled.h3`
  display: flex;
  text-transform: uppercase;
  border-radius: 8px;
  padding: 10px;
  background: #dfdfdf;
  font-size: 16px;

  &:before,
  &:after {
    content: "";
    position: relative;
    top: 11px;
    min-width: 30px;
    border-top: 1px solid;
    display: block;
    height: 0;
  }

  &:before {
    box-sizing: border-box;
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
    flex: 1 1 auto;
  }

  @media (max-width: 650px) {
    font-size: 16px;
  }
`;

export const StyledYearH3 = styled.h3`
  display: flex;
  width: max-content;

  padding: 5px 40px 5px 40px;
  background: rgba(78, 150, 169, 0.7);
  color: white;

  @media (max-width: 650px) {
    font-size: 16px;
  }
`;

export const StyledEventContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;

  @media (max-width: 650px) {
    display: block;
  }
`;

export const SectionContainer = styled.div`
  margin-top: 40px;
`;

type EventSimple = {
  title: string;
  date: string;
  time?: string;
  location: string;
  organizer?: string;
  weekday?: string;
};

const comingEvents: Array<EventSimple> = [
  {
    title: "Knutfest",
    // organizer: "Heimatverein Gräfendorf",
    location:
      "Gräfendorf-Dorfstraße 25 (bei Elvira und Frank Schlanke, hinten beim Teich)",
    date: "13.1.",
    weekday: "Sa",
  },
  {
    title: "Osterfeuer",
    // organizer: "Heimatverein Gräfendorf",
    location:
      "Gräfendorf-Dorfstraße 25 (bei Elvira und Frank Schlanke, hinten beim Teich)",
    date: "30.3.",
    weekday: "Sa",
  },
  {
    title: "Herrentag",
    organizer: "",
    location: "Bei der Freiwilligen Feuerwehr Gräfendorf",
    date: "09.5.",
    weekday: "Do",
  },
  {
    title: "Teichfest",
    // organizer: "Heimatverein Gräfendorf",
    location:
      "Gräfendorf-Dorfstraße 25 (bei Elvira und Frank Schlanke, vorne auf der Wiese)",
    date: "26.5.",
    weekday: "So",
  },
  {
    title: "Dorffest",
    organizer: "",
    location: "Park",
    date: "17.8.",
    weekday: "Sa",
  },
  {
    title: "Nachtpokal Gräfendorf",
    organizer: "",
    location: "Sportplatz Gräfendorf",
    date: "14.9.",
    time: "20:30 Uhr",
    weekday: "Sa",
  },
  {
    title: "Nikolausfest",
    organizer: "",
    location: "Dorfkirche Gräfendorf",
    date: "05.12.",
    weekday: "Do",
  },
];

const Home = () => {
  const renderComingEvents = comingEvents.map((event) => {
    return (
      <EventSimple
        title={event.title}
        date={event.date}
        time={event.time}
        location={event.location}
        organizer={event.organizer}
        weekday={event.weekday}
      />
    );
  });

  const getGridTemplateColumnsValue = () => {
    return comingEvents.length === 1 ? "1fr" : "1fr 1fr";
  };

  return (
    <Layout>
      {renderComingEvents.length ? (
        <SectionContainer>
          <UnderlinedH3>Veranstaltungen in Gräfendorf</UnderlinedH3>
          <StyledYearH3>Jahr 2024</StyledYearH3>
          {renderComingEvents}
        </SectionContainer>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Home;
