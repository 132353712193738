import React from "react";
import Layout from "../../Layout/Layout";
import EventArticle from "../../components/EventArticle";

const Weihnachtsbaumverbrennen = () => {
  const event = {
    title: "Knutfest",
    mainText:
      "Am 13.01.2024 findet das Knutfest (Weihnachtsbaumverbrennen) statt. Bringt eure Weihnachtsbäume und lasst uns gemeinsam das Ende der Weihnachtszeit markieren und die langsame Wiederkehr des Tageslichts begrüßen. Für Essen und Getränke sorgt Heimatverein e.V.",
    date: "13.01.2024 ab 18:00",
    location:
      "Gräfendorf-Dorfstraße 25, 14913 Niederer Fläming (bei Elvira und Frank Schlanke, hinten)",
    organizer: "Heimatverein Gräfendorf e.V.",
    imgPath: "weihnachtsbaumverbrennen.jpg",
  };
  return (
    <Layout>
      <EventArticle {...event} />
    </Layout>
  );
};

export default Weihnachtsbaumverbrennen;
