import styled from "styled-components";

export const StyledPhotosContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;

  @media (max-width: 650px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledPhoto = styled.div`
  border: 1px solid #ccc;
  padding: 1em;
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  :hover {
    cursor: zoom-in;
  }

  @media (max-width: 650px) {
  }
`;

export const StyledImg = styled.img`
  width: 100%;
  object-fit: fill; /* cover */
  height: auto;
  max-width: 400px;
`;

export const PhotoAuthorContainer = styled.div`
  color: #555;
  font-size: 12px;
  line-height: 20px;
  bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: -webkit-fill-available;
`;
export const PhotoFrom = styled.span``;
export const PhotoAuthor = styled.span`
  font-style: italic;
`;

export const LightboxModal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 200; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  align-items: center;
`;

export const LightboxModalContent = styled.div`
  background-color: #fefefe;
  margin: 50px auto; /* 15% from the top and centered */
  padding: 0 20px 20px 20px;
  border: 1px solid #888;
  //width: 90%; /* Could be more or less, depending on screen size */
  //max-width: 1500px;
  //  height: fit-content;
  width: auto;
`;

export const LightboxImage = styled.img`
  width: 100%;
`;

export const CloseButtonContainerModal = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 650px) {
    //min-height: 65px;
  }
`;

export const CloseButtonModal = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-end !important;
  cursor: pointer;

  &:hover {
    svg path {
      fill: #555;
    }
  }

  @media (max-width: 650px) {
    width: 40px;
    height: 40px;
  }
`;
