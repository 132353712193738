import React from "react";
import Layout from "../../Layout/Layout";
import { StyledEventContainer } from "../Home";
import PhotoGalleryCategory from "../../components/PhotoGalleryCategory";
import { UnderlinedH3 } from "../../App";

const PhotoGallery = () => {
  const categories = [
    {
      title: "Teich",
      route: "/bildergalerie/teich",
      imgPath: "gallery/teich/IMG-20240708-WA0009.jpg",
    },
    {
      title: "Park",
      route: "/bildergalerie/park",
      imgPath: "dorfkirche.png",
    },
    {
      title: "Diverses",
      route: "/bildergalerie/diverses",
      imgPath: "schloss-graefendorf.jpg",
    },
  ];
  const renderPhotoGalleryCategories = categories.map((event) => {
    return (
      <PhotoGalleryCategory
        title={event.title}
        imgPath={event.imgPath}
        route={event.route}
      />
    );
  });

  return (
    <Layout>
      <UnderlinedH3>Bildergalerie</UnderlinedH3>
      <StyledEventContainer>
        {renderPhotoGalleryCategories}
      </StyledEventContainer>
    </Layout>
  );
};

export default PhotoGallery;
