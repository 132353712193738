import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "../../components/CloseIcon";

const routes = [
  { title: "Startseite", path: "/" },
  {
    title: "Heimatverein",
    path: "/satzung",
    withDropdown: true,
    children: [
      { title: "Bildergalerie", path: "/bildergalerie" },
      { title: "Verein unterstützen", path: "/verein-unterstuetzen" },
      {
        title: "Vergangene Veranstaltungen",
        path: "/veranstaltungen",
      },
      { title: "Satzung", path: "/satzung" },
      { title: "Dokumente", path: "/dokumente" },
    ],
  },
  { title: "Projekte", path: "/projekte" },
  { title: "Gräfendorf", path: "/graefendorf" },
  { title: "Kontakt", path: "/kontakt" },
];

const NavbarContainer = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  background: rgba(78, 150, 169, 1);

  a,
  div {
    text-decoration: none;
    font-size: 19px;
    color: white;

    @media (max-width: 750px) {
      font-size: 18px;
    }

    @media (max-width: 650px) {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #333;
      padding-left: 40px;
      font-size: 17px;
      border-bottom: 1px solid #777;

      :last-child {
        border-bottom: 0;
      }
    }
  }

  @media (max-width: 650px) {
    min-height: 380px;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    box-shadow: 0 0 8px #555555;
  }

  @media (min-width: 651px) {
    height: 50px;
    display: flex;
    width: 100%;
    z-index: 100;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const CloseButtonContainer = styled.div`
  min-height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 6px;

  @media (max-width: 650px) {
    min-height: 65px;
  }
`;

const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 18px;
  display: flex;
  justify-content: flex-end !important;
  cursor: pointer;

  &:hover {
    svg path {
      fill: #555;
    }
  }

  @media (max-width: 650px) {
    width: 40px;
    height: 40px;
  }
`;

const StyledLinkDiv = styled.div`
  padding: 0 14px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 650px) {
    padding: 0;
    height: 60px !important;
  }

  &:hover {
    background: rgba(70, 135, 152, 1);

    @media (max-width: 650px) {
      background: #add8e6;
    }
  }
`;

const StyledLinkSubContainer = styled.div<{ openSubmenu?: boolean }>`
  position: absolute;
  top: 50px;
  margin-left: -14px;
  background: rgba(78, 150, 169, 1);
  min-width: 280px;

  @media (max-width: 650px) {
    position: relative;
    margin-top: 214px;
    background: #add8e6;
    padding-left: 20px !important;
  }

  a {
    padding: 16px 10px 16px 14px;
    font-size: 18px;
    @media (max-width: 650px) {
      font-size: 16px;
    }
  }
`;

const StyledSpan = styled.span<{ openSubmenu?: boolean }>`
  @media (max-width: 650px) {
    position: ${(props) => (props.openSubmenu ? "absolute" : "static")};
    top: 19px;
  }
`;

const StyledLink = styled(Link)`
  padding: 0 14px;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 650px) {
    padding: 0;
  }

  &:hover {
    background: rgba(70, 135, 152, 1);

    @media (max-width: 650px) {
      background: #add8e6;
    }
  }
`;

const Navbar = ({
  isMobileView,
  clickedMenuOpen,
  setClickedMenuOpen,
}: {
  isMobileView: boolean;
  clickedMenuOpen: boolean;
  setClickedMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const links = routes.map((route) => {
    if (route.withDropdown) {
      const children = route.children.map((child) => (
        <StyledLink to={child.path}>{child.title}</StyledLink>
      ));
      return (
        <StyledLinkDiv
          onMouseOver={() => setOpenSubmenu(true)}
          onMouseOut={() => setOpenSubmenu(false)}
          onClick={() => setOpenSubmenu(!openSubmenu)} /**/
        >
          <StyledSpan openSubmenu={openSubmenu}>{route.title}</StyledSpan>
          <StyledLinkSubContainer
            style={{
              visibility: openSubmenu ? "visible" : "hidden",
              display: openSubmenu ? "inline-table" : "flex",
              width: "-webkit-fill-available",
              // paddingLeft:
            }}
          >
            {children}
          </StyledLinkSubContainer>
        </StyledLinkDiv>
      );
    }
    return <StyledLink to={route.path}>{route.title}</StyledLink>;
  });
  const renderMobileView = useMemo(() => {
    if (isMobileView) {
      if (setClickedMenuOpen && clickedMenuOpen) {
        return (
          <NavbarContainer id="main-navbar">
            <CloseButtonContainer>
              <CloseButton onClick={() => setClickedMenuOpen(false)}>
                <CloseIcon />
              </CloseButton>
            </CloseButtonContainer>
            {links}
          </NavbarContainer>
        );
      } else {
        return null;
      }
    } else {
      return <NavbarContainer id="main-navbar">{links}</NavbarContainer>;
    }
  }, [clickedMenuOpen, isMobileView, links, setClickedMenuOpen]);

  return <>{renderMobileView}</>;
};

export default Navbar;
