import React from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
import PhoneIcon from "../components/PhoneIcon";
import { IconContainer } from "./Contact";
import EmailIcon from "../components/EmailIcon";
import LocationPinIcon from "../components/LocationPinIcon";

const Block = styled.div`
  line-height: 28px;
`;

const Section = styled.div`
  margin: 40px 0;

  h4 {
    margin-bottom: 10px;
    text-decoration: underline;
  }
  p {
    margin: 0;
  }
`;

const Contact = styled.div`
  line-height: 18px;
  //margin-bottom: 60px;
`;

const Entry = styled.div`
  width: 100%;
  display: flex;
  min-height: 40px;
  align-items: center;
  padding: 8px 0;
`;

const Left = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
`;

const ImprintContainer = styled.div`
  p {
    //margin-bottom: 8px;
  }
`;

const Imprint = () => {
  return (
    <Layout>
      <ImprintContainer>
        <h3>Impressum</h3>
        <Section>
          <h4>Heimatverein Gräfendorf e.V.:</h4>
          <p>Gräfendorf-Dorfstraße 23</p>
          <p>14913 Niederer Fläming</p>
          <p>Deutschland</p>
        </Section>

        <Section>
          <h4>Vertreten durch:</h4>
          <p>Vorstandsvorsitzender: Herr Mirko Möhle</p>
          <p>Stellvertretende Vorsitzende: Frau Andrea Richter</p>
        </Section>

        <Section>
          <h4>Kontakt:</h4>
          <Contact>
            <Entry>
              <Left>
                <IconContainer style={{ marginRight: "10px" }}>
                  <EmailIcon />
                </IconContainer>
                <span>E-Mail:</span>
              </Left>
              <span>info@graefendorf-heimatverein.de</span>
            </Entry>
            <Entry
              style={{
                height: "max-content",
                display: "flex",
                alignItems: "flex-start",
                marginTop: "10px",
              }}
            >
              <Left>
                <IconContainer style={{ marginRight: "10px" }}>
                  <LocationPinIcon className="fillSvgPath" />
                </IconContainer>
                <span>Adresse:</span>
              </Left>
              <Block style={{ marginTop: "2px" }}>
                <Left></Left>
                <span>Gräfendorf Dorfstraße 23</span>
                <Left></Left>
                <span>14913 Niederer Fläming</span>
                <Left></Left>
                <span>Deutschland</span>
              </Block>
            </Entry>
            {/*<Entry>*/}
            {/*  <Left>*/}
            {/*    <IconContainer style={{ marginRight: "20px" }}>*/}
            {/*      <PhoneIcon />*/}
            {/*    </IconContainer>*/}
            {/*    <span>Telefon:</span>*/}
            {/*  </Left>*/}
            {/*  <span>+49 30 123 4567</span>*/}
            {/*</Entry>*/}
            {/*<Entry>*/}
            {/*  <Left>*/}
            {/*    <IconContainer style={{ marginRight: "20px" }}>*/}
            {/*      <LocationPinIcon className="fillSvgPath" />*/}
            {/*    </IconContainer>*/}
            {/*    <span>Adresse:</span>*/}
            {/*  </Left>*/}
            {/*  <span>Gräfendorf-Dorfstraße 23, 14913 Niederer Fläming</span>*/}
            {/*</Entry>*/}
          </Contact>
        </Section>
        <Section>
          <h4>Registereintrag:</h4>
          <p>Eintragung im Vereinsregister</p>
          <p>Registergericht: Amtsgericht Potsdam</p>
          <p>Registernummer: VR 9623 P</p>
        </Section>

        <Section>
          <h4>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h4>
          <p>Vladimir Santrac</p>
        </Section>
      </ImprintContainer>
    </Layout>
  );
};

export default Imprint;
