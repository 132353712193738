import React, { useState } from "react";
import Layout from "../../../Layout/Layout";
import {
  CloseButtonContainerModal,
  CloseButtonModal,
  LightboxImage,
  LightboxModal,
  LightboxModalContent,
  PhotoAuthor,
  PhotoAuthorContainer,
  PhotoFrom,
  StyledImg,
  StyledPhoto,
  StyledPhotosContainer,
} from "../../../components/StyledComponents";
import CloseIcon from "../../../components/CloseIcon";
import { UnderlinedH3 } from "../../../App";

export const PhotoGalleryPark = () => {
  const [lightboxImage, setLightboxImage] = useState<null | {
    path: string;
    author: string;
  }>(null);

  const photos = [
    {
      path: "/gallery/park/IMG-20240708-WA0010.jpg",
      author: "Sabrina Möhle",
    },
    {
      path: "/gallery/park/IMG-20240708-WA0011.jpg",
      author: "Sabrina Möhle",
    },
    {
      path: "/gallery/park/IMG-20240708-WA0020.jpg",
      author: "Sabrina Möhle",
    },
  ];

  const renderGallery = photos.map((photo) => {
    return (
      <StyledPhoto>
        <StyledImg
          src={photo.path}
          onClick={() => setLightboxImage(photo)}
          style={{ height: "120px", objectFit: "cover" }}
        />
        {photo.author && (
          <PhotoAuthorContainer
            style={{ position: lightboxImage ? "relative" : "absolute" }}
          >
            <PhotoFrom>Von: </PhotoFrom>
            <PhotoAuthor>{photo.author}</PhotoAuthor>
          </PhotoAuthorContainer>
        )}
      </StyledPhoto>
    );
  });

  const isMobileView = window.innerWidth <= 650;

  return (
    <Layout>
      <UnderlinedH3>Bildergalerie - Park</UnderlinedH3>
      <StyledPhotosContainer>{renderGallery}</StyledPhotosContainer>

      {lightboxImage && (
        <LightboxModal id="myModal">
          <LightboxModalContent
            className="modal-content"
            style={{
              maxHeight: isMobileView
                ? `${window.innerHeight - 20}px`
                : `${window.innerHeight - 100}px`,
              maxWidth: isMobileView
                ? `${window.innerWidth - 20}px`
                : `${window.innerWidth - 100}px`,
            }}
          >
            <CloseButtonContainerModal
              style={{
                justifyContent: lightboxImage.author ? "space-between" : "end",
              }}
            >
              {lightboxImage.author && (
                <PhotoAuthorContainer
                  style={{ position: lightboxImage ? "relative" : "absolute" }}
                >
                  <PhotoFrom>Von: </PhotoFrom>
                  <PhotoAuthor>{lightboxImage.author}</PhotoAuthor>
                </PhotoAuthorContainer>
              )}
              <CloseButtonModal onClick={() => setLightboxImage(null)}>
                <CloseIcon />
              </CloseButtonModal>
            </CloseButtonContainerModal>
            <LightboxImage
              src={lightboxImage.path}
              style={{
                maxHeight: isMobileView
                  ? `${window.innerHeight - 100}px`
                  : `${window.innerHeight - 180}px`,
                maxWidth: isMobileView
                  ? `${window.innerWidth - 100}px`
                  : `${window.innerWidth - 180}px`,
              }}
            />
          </LightboxModalContent>
        </LightboxModal>
      )}
    </Layout>
  );
};

export default PhotoGalleryPark;
