import React from "react";
import Layout from "../../Layout/Layout";
import EventArticle from "../../components/EventArticle";

const Teichfest = () => {
  const event = {
    title: "Teichfest",
    mainText:
      "Am 26.05.2024 findet das jährliche Teichfest statt. Vorne auf dem Rasen, bei Elvira und Frank Schlanke, werden Zelte, Tische und Bänke aufgestellt. Versorgung mit Essen und Getränken übernimmt Schirrmi's Allerlei. Zusätzlich gibt es Kuchen und Torten, versorgt durch unsere fleißigen Dorfbewohner. Für musikalische Unterhaltung ist gesorgt: u.a. unser Dorfchor, geführt von Erika Wolschke, sowie ein DJ.",
    date: "26.05.2024 ab 13:00",
    location:
      "Gräfendorf-Dorfstraße 25, 14913 Niederer Fläming (bei Elvira und Frank Schlanke, vorne)",
    organizer: "Erika Wolschke",
    imgPath: "gallery/teich/IMG-20240708-WA0009.jpg",
  };
  return (
    <Layout>
      <EventArticle {...event} />
    </Layout>
  );
};

export default Teichfest;
