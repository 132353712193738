import React from "react";
import Layout from "../../Layout/Layout";
import styled from "styled-components";

const ImgContainer = styled.div`
  text-align: left;
`;

const StyledImg = styled.img`
  width: 100%;
  object-fit: fill; /* cover */
  height: auto;
  max-width: 400px;
`;

const Park = () => {
  return (
    <Layout>
      <h3>Parkverschönerung</h3>
      <ImgContainer>
        <StyledImg src="dorfkirche.png" />
      </ImgContainer>
      <p>
        Unser schöner Park ist die Ruheoase des Dorfes, in der jeder Kraft
        tanken kann. Kinder toben gerne dort herum. Zum Dorffest verwandelt sich
        der Park in einen Veranstaltungsort, mit Bühne und Attraktionen. Zu
        Ostern findet dort das jährliche Eierrollen der Kinder statt. Die Bäume
        spenden Schatten, die Wege laden zum spazieren und verweilen ein. Es ist
        daher unser Anliegen den Park für alle Dorfbewohner weiterhin attraktiv
        zu machen.
      </p>
    </Layout>
  );
};

export default Park;
