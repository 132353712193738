import Layout from "../../Layout/Layout";
import React from "react";
import styled from "styled-components";
import { IconContainer } from "../Contact";
import DownloadIcon from "../../components/DownloadIcon";
import EyeIcon from "../../components/EyeIcon";
import { UnderlinedH3 } from "../../App";

const StyledHref = styled.a`
  color: black;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  min-height: 40px;
  align-items: center;
  padding: 8px 0;
`;

const Left = styled.div`
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
`;

export const IconContainerNoFill = styled.div`
  width: 30px;
  height: 30px;

  svg {
    fill: rgb(0, 51, 102);
    height: 30px;
    width: 30px;
  }
`;

const Documents = () => {
  return (
    <Layout>
      <UnderlinedH3>Dokumente des H.V. Gräfendorf</UnderlinedH3>

      <Item>
        <Left>
          <StyledHref
            href="/Beitrittserklaerung-HV-Graefendorf-eV.pdf"
            download="Beitrittserklaerung-HV-Graefendorf-eV.pdf"
          >
            <IconContainer>
              <DownloadIcon />
            </IconContainer>
          </StyledHref>
          <StyledHref
            href="/Beitrittserklaerung-HV-Graefendorf-eV.pdf"
            target="_blank"
          >
            <IconContainerNoFill>
              <EyeIcon />
            </IconContainerNoFill>
          </StyledHref>
        </Left>
        Beitrittserklärung für Heimatverein Gräfendorf e.V.
      </Item>
    </Layout>
  );
};

export default Documents;
