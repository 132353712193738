import React from "react";
import styled from "styled-components";

const ImgContainer = styled.div`
  text-align: left;
`;

const StyledImg = styled.img`
  width: 100%;
  object-fit: fill; /* cover */
  height: auto;
  max-width: 400px;
`;

const Entry = styled.div`
  width: 100%;
  display: flex;
  min-height: 40px;
  align-items: flex-start;
  padding: 8px 0;
`;

const Left = styled.div`
  min-width: 130px;
  display: flex;
  align-items: flex-start;
  font-weight: bold;
  text-decoration: underline;
`;

type TEventProps = {
  title: string;
  mainText: string;
  date?: string;
  location: string;
  organizer?: string;
  imgPath: string;
};

const EventArticle = ({
  title,
  mainText,
  location,
  date,
  organizer,
  imgPath,
}: TEventProps) => {
  return (
    <>
      <h3>{title}</h3>
      <ImgContainer>
        <StyledImg src={imgPath} />
      </ImgContainer>
      <p style={{ marginTop: "30px" }}>{mainText}</p>

      <Entry>
        {" "}
        <Left>Wo: </Left>
        <span>{location}</span>
      </Entry>
      <Entry>
        {" "}
        <Left>Wann: </Left>
        <span>{date} </span>
      </Entry>
      <Entry>
        <Left>Organisator: </Left>
        <span>{organizer}</span>
      </Entry>
    </>
  );
};

export default EventArticle;
