import React from "react";
import Layout from "../../Layout/Layout";
import EventArticle from "../../components/EventArticle";

const Osterfeuer = () => {
  const event = {
    title: "Osterfeuer",
    mainText:
      "Am 30.03.2024 findet das Osterfeuer statt. Bringt eure trockenen Äste und genießt das Feuer in einer gemütlichen Runde! Versorgung mit Essen und Getränken übernimmt Schirrmi's Allerlei.",
    date: "30.03.2024 ab 18:00",
    location:
      "Gräfendorf-Dorfstraße 25, 14913 Niederer Fläming (bei Elvira und Frank Schlanke, hinten)",
    organizer: "Heimatverein Gräfendorf e.V.",
    imgPath: "osterfeuer.png",
  };
  return (
    <Layout>
      <EventArticle {...event} />
    </Layout>
  );
};

export default Osterfeuer;
