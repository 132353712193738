import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer";
import styled from "styled-components";

const ContentContainer = styled.div`
  text-align: left;
  line-height: 28px;
  margin-bottom: 20px;
  height: calc(100% - 30px);

  @media (max-width: 650px) {
    padding: 0;
  }
`;

const InnerContainer = styled.div`
  padding: 0 40px;
  padding-bottom: 60px;

  @media (max-width: 750px) {
    width: 100vw;
    padding: 0 32px;
    padding-bottom: 60px;
  }
  @media (max-width: 650px) {
    width: 100vw;
    padding: 0 24px;
    padding-bottom: 60px;
  }
  @media (min-width: 651px) {
    margin-bottom: 30px;
  }
`;

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Header />
      <InnerContainer>
        <ContentContainer>{children}</ContentContainer>
      </InnerContainer>
      <Footer />
    </>
  );
};

export default Layout;
