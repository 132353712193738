import { Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import Statute from "./views/Club/Statute";
import Documents from "./views/Club/Documents";
import PhotoGallery from "./views/Club/PhotoGallery";
import SupportUs from "./views/Club/SupportUs";
import Contact from "./views/Contact";
import Projects from "./views/Projects";
import Privacy from "./views/Privacy";
import Imprint from "./views/Imprint";
import EventTeichfest from "./views/Events/Teichfest";
import "./App.css";
import styled from "styled-components";
import Graefendorf from "./views/Graefendorf";
import EventOsterfeuer from "./views/Events/Osterfeuer";
import EventWeihnachtsbaumverbrennen from "./views/Events/Weihnachtsbaumverbrennen";
import ProjectPark from "./views/Projects/Park";
import ProjectTeich from "./views/Projects/Teich";
import PhotoGalleryTeich from "./views/Club/PhotoGalleryCategory/Teich";
import PhotoGalleryVarious from "./views/Club/PhotoGalleryCategory/Various";
import PhotoGalleryPark from "./views/Club/PhotoGalleryCategory/Park";
import PastEvents from "./views/Club/PastEvents";

const AppContainer = styled.div`
  max-width: 1000px;
  width: 100%;
  height: calc(100% - 20px);
  background-size: auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 8px #555555;
  position: relative;

  @media (max-width: 650px) {
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const UnderlinedH3 = styled.h3`
  text-decoration: underline;
`;

function App() {
  return (
    <AppContainer className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="satzung" element={<Statute />} />
        <Route path="dokumente" element={<Documents />} />
        <Route path="bildergalerie" element={<PhotoGallery />} />
        <Route path="bildergalerie/teich" element={<PhotoGalleryTeich />} />
        <Route
          path="bildergalerie/diverses"
          element={<PhotoGalleryVarious />}
        />
        <Route path="bildergalerie/park" element={<PhotoGalleryPark />} />
        <Route path="verein-unterstuetzen" element={<SupportUs />} />
        <Route path="veranstaltungen" element={<PastEvents />} />
        <Route path="projekte" element={<Projects />} />
        <Route path="graefendorf" element={<Graefendorf />} />
        <Route path="kontakt" element={<Contact />} />
        <Route path="impressum" element={<Imprint />} />
        <Route path="datenschutz" element={<Privacy />} />
        <Route path="veranstaltungen/26-5" element={<EventTeichfest />} />
        <Route path="veranstaltungen/19-4" element={<EventOsterfeuer />} />
        <Route
          path="veranstaltungen/13-1"
          element={<EventWeihnachtsbaumverbrennen />}
        />
        <Route path="projekte/teich" element={<ProjectTeich />} />
        <Route path="projekte/park" element={<ProjectPark />} />
      </Routes>
    </AppContainer>
  );
}

export default App;
