import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 30px;

  cursor: pointer;
`;

const ImgContainer = styled.div`
  text-align: center;
  position: relative;
`;

const StyledImg = styled.img`
  width: 100%;
  object-fit: cover;
  height: 250px;

  @media (max-width: 850px) {
    height: 230px;
  }
  @media (max-width: 800px) {
    height: 220px;
  }
  @media (max-width: 750px) {
    height: 210px;
  }
  @media (max-width: 750px) {
    height: 200px;
  }
  @media (max-width: 650px) {
    height: 270px;
  }
  @media (max-width: 500px) {
    height: 260px;
  }
  @media (max-width: 450px) {
    height: 240px;
  }
  @media (max-width: 400px) {
    height: 210px;
  }
  @media (max-width: 350px) {
    height: 190px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const TitleContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 10px;
  background: rgba(255, 255, 255, 1);
  padding: 2px 10px;

  &:hover {
    background-color: rgb(190, 215, 223);
    transition: 0.3s;
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: #555;
  text-align: center;
  font-size: 18px;
`;

type TEventProps = {
  title: string;
  imgPath: string;
  route: string;
};

const PhotoGalleryCategory = ({ title, imgPath, route }: TEventProps) => {
  return (
    <Container>
      <StyledLink to={route}>
        <ImgContainer>
          <StyledImg src={imgPath} />
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
        </ImgContainer>
      </StyledLink>
    </Container>
  );
};

export default PhotoGalleryCategory;
