import React from "react";
import Layout from "../../Layout/Layout";
import styled from "styled-components";
import { UnderlinedH3 } from "../../App";

const Block = styled.div`
  line-height: 16px;
  margin-top: 60px;
`;

const Entry = styled.div`
  width: 100%;
  display: flex;
  min-height: 30px;
  padding: 8px;
`;

const Left = styled.div`
  min-width: 175px;
`;

const SupportUs = () => {
  return (
    <Layout>
      <UnderlinedH3>Heimatverein unterstützen</UnderlinedH3>
      <p>So können Sie den Heimatverein unterstützen:</p>
      <Block>
        <h4>Zahlungsinfos:</h4>
        <Entry>
          <Left>Zahlungsempfänger:</Left>
          <div>Heimatverein Gräfendorf e.V.</div>
        </Entry>
        <Entry>
          <Left>IBAN:</Left>
          <div>DE82 1606 2008 2116 7877 00</div>
        </Entry>
        <Entry>
          <Left>Verwendungszweck:</Left>
          <div>Spende</div>
        </Entry>
      </Block>
    </Layout>
  );
};

export default SupportUs;
