import React from "react";
import Layout from "../Layout/Layout";
import { UnderlinedH3 } from "../App";

const Graefendorf = () => {
  return (
    <Layout>
      <UnderlinedH3>Gräfendorf</UnderlinedH3>
      <p>
        Gräfendorf wurde erstmals 1205/21 erwähnt und zählt bis 1566 zum
        Erzbistum, bzw. bis 1635 zum Administrator von Magdeburg. Seit dem
        Mittelalter war das Dorf im Besitz der Familie von Thümen, später bis
        1945 im Besitz von Franz Stein.
      </p>
      <p>
        1221 gab es eine Mühle im unteren Teil des Dorfes. Überlieferungen aus
        dem Jahre 1638 dokumentieren, dass das Dorf weitgehend zerstört wurde.
        Im Jahre 1727 fand man in dem großen Pfuhl einen von Eichenholz
        ausgeschälten Brunnen, der auf die ehemalige Siedlung stieß. 1777 sind
        vier publike Häuser, ein Hausgenossenhaus auf ritterschaftlichem Grund,
        eine Schmiede und ein Gemeindehirtenhaus erwähnt.1815/16 gab es im Dorf
        eine Windmühle, ein Hufschmied, eine Brauerei, eine Weinbrandbrennerei,
        eine Ziegelei und ein Schankwirt. Schon 1853 wurde die Hälfte des
        kleinen Pfuhls für den Bau der Dorfstraße beseitigt.
      </p>
      <p>
        Ab 1891 ist Gräfendorf Kirchendorf mit Rittergut und Schäferei. Das
        barocke Gutsschloss wurde im Zusammenhang mit der Bodenreform
        abgerissen. Der noch erhaltene Gutspark, aus der zweiten Hälfte des 19.
        Jahrhunderts, mit altem Baumbestand dokumentiert weiterhin die Epochen
        des Junkertums.
      </p>
      <p>
        1931 wurde Gräfendorf Landgemeinde. Die ehemalige Fläche des Gutshofes
        von ca. 1,12 ha wurde ab 1950 als Gärtnerei für Gemüsepflanzen genutzt.
        1956 wurde die LPG „Fortschritt“ gegründet, die sich erweiterte und sich
        später mit der LPG in Werbig zusammenschloss. Geprägt wird das
        Straßendorf durch den baumreichen Gutspark und den nahezu
        gegenüberliegenden großen Dorfteich. Der Dorfeingang von Gräfendorf wird
        durch eine Linden-Allee begleitet. Die spätromanische Feldsteinkirche,
        mit eingezogenem Chor, im Kern des Dorfes, wurde im 14. Jahrhundert
        erbaut und ist das älteste Gebäude.
      </p>
      <p>Einwohner: 164 (Stand 31.12.2020 / Erfassung 18.02.2021)</p>
    </Layout>
  );
};

export default Graefendorf;
